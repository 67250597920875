import React from "react"
import { graphql, StaticQuery } from "gatsby"
import RenderRichText from "../../utils/contentful-rich-text"
import { Gmap } from "./gmap"

export const clubQuery = graphql`
  query clubQuery {
    contentfulClub {
      body {
        raw
      }
    }
  }
`;

export default function Club() {
  return (
    <StaticQuery
      query={clubQuery}
      render={data => {
        return (
          <>
            <section className="px-4 mt-6 text-justify md:mx-auto md:max-w-7xl">
              {RenderRichText(data.contentfulClub.body)}
            </section>
            <Gmap />
          </>
        )
      }}
    />
  )
}

